import {prepareImageUrl} from '@/helper/ImageHelper';

export function getMetaTags(metaData) {
  const tags = [];

  if (metaData.title) {
    tags.push(
      {
        property: 'og:title',
        content: metaData.title,
      },
      {
        name: 'twitter:title',
        content: metaData.title,
      }
    );
  }
  if (metaData.description) {
    tags.push(
      {
        hid: 'description',
        name: 'description',
        content: metaData.description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: metaData.description,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: metaData.description,
      }
    );
  }
  if (metaData.keywords) {
    tags.push({
      hid: 'keywords',
      name: 'keywords',
      content: metaData.keywords,
    });
  }
  if (metaData.share_image) {
    const imageUrl = prepareImageUrl(metaData.share_image.url);
    tags.push(
      {
        hid: 'image',
        name: 'image',
        content: imageUrl,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageUrl,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageUrl,
      }
    );
  }
  if (metaData.article) {
    tags.push({
      property: 'og:type',
      content: 'article',
    });
  }
  if (Object.keys(metaData).length > 0) {
    tags.push({name: 'twitter:card', content: 'summary_large_image'});
  }
  if (metaData.prevent_indexing) {
    tags.push({
      name: 'robots',
      content: 'noindex',
    });
    tags.push({
      name: 'googlebot',
      content: 'noindex',
    });
  }

  return tags;
}

export function replaceActivityDataInMetaData(metaData, activity) {
  // Replacement für Metadaten
  if (metaData && metaData.title) {
    metaData.title = metaData.title.replace(/%activity_name/g, activity.name);
  }
  if (metaData && metaData.description) {
    metaData.description = metaData.description.replace(
      /%activity_name/g,
      activity.name
    );
  }
  if (metaData && metaData.keywords) {
    metaData.keywords = metaData.keywords.replace(
      /%activity_name/g,
      activity.name
    );
  }

  // Use share_image from city when defined
  if (activity.meta && activity.meta.share_image) {
    metaData.share_image = activity.meta.share_image;
  }

  return metaData;
}

export function replaceCityDataInMetaData(metaData, city) {
  // Replacement für Metadaten
  if (metaData && metaData.title) {
    metaData.title = metaData.title.replace(/%city_name/g, city.name);
  }
  if (metaData && metaData.description) {
    metaData.description = metaData.description.replace(
      /%city_name/g,
      city.name
    );
  }
  if (metaData && metaData.keywords) {
    metaData.keywords = metaData.keywords.replace(/%city_name/g, city.name);
  }

  // Use share_image from city when defined
  if (city.meta && city.meta.share_image) {
    metaData.share_image = city.meta.share_image;
  }

  return metaData;
}

export function replaceOrganizationDataInMetaData(metaData, organization) {
  // Replacement für Metadaten
  if (metaData && metaData.title) {
    metaData.title = metaData.title.replace(
      /%company_name/g,
      organization.name
    );
    metaData.title = metaData.title.replace(
      /%organization_name/g,
      organization.name
    );
  }
  if (metaData && metaData.description) {
    metaData.description = metaData.description.replace(
      /%company_name/g,
      organization.name
    );
    metaData.description = metaData.description.replace(
      /%organization_name/g,
      organization.name
    );
  }
  if (metaData && metaData.keywords) {
    metaData.keywords = metaData.keywords.replace(
      /%company_name/g,
      organization.name
    );
    metaData.keywords = metaData.keywords.replace(
      /%organization_name/g,
      organization.name
    );
  }

  return metaData;
}
