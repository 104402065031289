
  import Events from '@/events/Events';
  import {mapSectionComponents} from '@/helper/SectionHelper';
  import {getMetaTags} from '@/helper/Seo';
  import LazyHydrate from 'vue-lazy-hydration';
  import {scopeHelper} from '@/helper/ScopeHelper';

  export default {
    name: 'Index',
    components: {
      LazyHydrate,
      ForgotPasswordDialog: () =>
        import('@/components/candidate/ForgotPasswordDialog'),
    },
    async asyncData({error, $cachedStrapi}) {
      const page = await $cachedStrapi.findOne('pages', 'home').catch((e) => {
        if (e.statusCode !== 404) {
          throw e;
        }
      });

      if (!scopeHelper(page, process.env.SCOPE_CANDIDATE)) {
        error({statusCode: 404, message: 'Page not found'});
        return;
      }

      const metaData = Object.freeze(page && page.meta ? page.meta : {});

      const sections = Object.freeze(
        await mapSectionComponents(page.sections, async function (section) {
          switch (section.component) {
            case 'SectionSeoContent':
              if (
                section.seo_content_config &&
                section.seo_content_config.seo_contents &&
                section.seo_content_config.seo_contents[0]
              ) {
                section.seoContent = await $cachedStrapi.findOne(
                  'seo-contents',
                  section.seo_content_config.seo_contents[0].id
                );
              }
              break;
          }
        })
      );

      return {
        metaData,
        sections,
      };
    },
    data() {
      return {
        metaData: {},
        sections: [],
        resetPasswordToken: false,
        currentPage: '',
      };
    },
    head() {
      return {
        title: this.metaData.title,
        meta: getMetaTags(this.metaData),
      };
    },
    computed: {
      passwordToken() {
        return this.$route.query.resetPasswordToken;
      },
      emailToken() {
        return this.$route.query.verifyEmailToken;
      },
    },
    created() {
      if (!process.server && this.emailToken) {
        this.$store
          .dispatch('api/candidateAccount/verifyCandidateEmail', {
            token: this.emailToken,
          })
          .then((response) => {
            if (response.status === 200) {
              this.$auth.fetchUser();
              this.$notifications.showNotification({
                message: this.$t('account.email.confirmed'),
                color: 'success',
                timeout: 5000,
              });
              this.$emitWithClientData(Events.EmailVerified);
            }
          })
          .catch((e) => {
            if (e.response.status !== 400) {
              throw e;
            }
            if (e.response.data === 'The email was already verified.') {
              this.$notifications.showNotification({
                message: this.$t('account.email.already_confirmed'),
                color: 'warning',
                timeout: -1,
              });
            } else {
              this.$notifications.showNotification({
                message: this.$t('account.email.not_confirmed'),
                color: 'error',
                timeout: -1,
              });
            }
          });
      }
      if (!process.server && this.passwordToken) {
        this.$store
          .dispatch('api/candidateAccount/validateResetPasswordToken', {
            token: this.passwordToken,
          })
          .then((response) => {
            if (response.status === 200) {
              this.currentPage = 'confirmPassword';
              this.resetPasswordToken = true;
            }
          })
          .catch((e) => {
            if (e.response.status !== 400) {
              throw e;
            }
            this.currentPage = 'forgotPassword';
            this.resetPasswordToken = true;
            this.$notifications.showNotification({
              message: this.$t('forgot.password.token_not_valid'),
              color: 'warning',
              timeout: 5000,
            });
          });
      }
      if (!process.server && this.$route.query.delete_alert) {
        this.$store
          .dispatch('api/jobAlerts/deleteJobAlert', {
            id: this.$route.query.delete_alert,
          })
          .then(() => {
            this.$notifications.showNotification({
              message: this.$t('job_alert.element_notification_delete'),
              color: 'success',
              timeout: 3000,
            });
            this.$emitWithClientData(Events.JobAlertUnsubscribed, {});
          })
          .catch((e) => {
            if (e.response.status != 204) {
              this.$notifications.showNotification({
                message: this.$t('job_alert.element_notification_delete_error'),
                color: 'error',
                timeout: 3000,
              });
            }
          });
      }
      if (!process.server && this.$route.query.confirm_alert) {
        this.$store
          .dispatch('api/jobAlerts/confirmJobAlert', {
            id: this.$route.query.confirm_alert,
          })
          .then(() => {
            this.$notifications.showNotification({
              message: this.$t('job_alert.element_notification_confirm'),
              color: 'success',
              timeout: 3000,
            });
          })
          .catch((e) => {
            if (e.response.status != 204) {
              this.$notifications.showNotification({
                message: this.$t(
                  'job_alert.element_notification_confirm_error'
                ),
                color: 'error',
                timeout: 3000,
              });
            }
          });
      }
    },
  };
